
.social-media-share {
    position: relative;    
    display: inline-block;
    z-index: 100000000 !important;  /*not taking effect*/
  }
  
  .share-button {
    /* background-color: #007bff; */
    /* background-color: #000; */
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
  }
  
   up {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 100%;
    right: 0;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 1000;
  }
  
  .social-icon {
    text-decoration: none;
    color: #333;
    padding: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  
  .social-icon:hover {
    /* background-color: #f0f0f0; */
    /* background-color: 'red'; */
    /* color: #000000 */
    border: #f0f0f0 2px solid;
  }
  
  .social-icon i {
    margin-right: 8px;
  }
  
  
  .facebook {
    color: #4267b2;
  }
  
  .twitter {
    color: #1da1f2;
  }
  
  .linkedin {
    color: #0077b5;
  }
  
  .overlay {
    position: absolute; /* Position the overlay over the content */
  }