   .santa-hat {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: auto;
    z-index: 10;
    animation: swing 1.5s infinite ease-in-out; /* Faster and more noticeable */
  }
  
  @keyframes swing {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    20% {
      transform: translate(-50%, -50%) rotate(25deg); /* Larger swing to the right */
    }
    50% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    80% {
      transform: translate(-50%, -50%) rotate(-25deg); /* Larger swing to the left */
    }
    100% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  }
  