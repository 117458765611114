/* Cookie Banner Container */
.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.85); /* Dark background with transparency */
    color: white;
    text-align: center;
    padding: 20px;
    z-index: 9999;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
    animation: slideUp 0.5s ease-out;
  }
  
  /* Slide Up Animation */
  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  /* Cookie Content Styling */
  .cookie-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
  }
  
  /* Cookie Text Styling */
  .cookie-text {
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
  }
  
  .cookie-link {
    color: #00d1b2; /* Custom Ekki color */
    text-decoration: none;
  }
  
  .cookie-link:hover {
    text-decoration: underline;
  }
  
  /* Button Styling */
  .cookie-button {
    background-color: #00d1b2; /* Match your site's primary color */
    border: none;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 15px;
    transition: background-color 0.3s ease;
  }
  
  .cookie-button:hover {
    background-color: #00bfa3;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .cookie-content {
      flex-direction: column;
      align-items: center;
    }
  
    .cookie-button {
      margin-top: 10px;
    }
  }
  