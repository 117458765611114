.truncate-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  @media (min-width: 768px) { /* For larger screens */
    .truncate-text {
      width: 200ch;  /* Display 50 characters */
    }
  }
  
  @media (max-width: 767px) { /* For smaller screens */
    .truncate-text {
      width: 200ch;  /* Display 20 characters */
    }
  }
  